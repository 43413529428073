<template>
  <div class="row justify-content-center">
    <home-header></home-header>

    <vs-prompt
      title=""
      @accept="stamp_activity"
      accept-text="ตกลง"
      cancel-text="ยกเลิก"
      @close="close"
      :active.sync="stampDefaultPrompt"
    >
      <div class="con-exemple-prompt">
        ต้องการประทับตรา "{{ default_electronic_stamp }}" ให้กับรถหมายเลข
        {{ license_plate_id }} ?
      </div>
    </vs-prompt>

    <vs-prompt
      color="primary"
      title="ประทับตรา"
      accept-text="บันทึก"
      cancel-text="ยกเลิก"
      @cancel="stamp = ''"
      @accept="updateTrans"
      @close="close"
      :is-valid="validForm"
      :active.sync="activePrompt2"
    >
      <div class="con-exemple-prompt">
        กรุณาเลือกตราประทับ
        <ul style="margin-top: 20px">
          <label
            style="margin-left: 10px"
            class="vs-component con-vs-radio vs-radio-primary"
            v-for="(item, index) in stampItems"
            :key="index"
          >
            <input
              v-model="stamp"
              name="stamp"
              :value="item"
              type="radio"
              class="vs-radio--input"
            />
            <span class="vs-radio">
              <span
                class="vs-radio--borde"
                style="border: 2px solid rgb(200, 200, 200)"
              ></span>
              <span class="vs-radio--circle"></span>
            </span>
            <span class="vs-radio--label">{{ item }}</span>
          </label>
        </ul>
        <vs-alert
          style="margin-top: 20px"
          :active="!validForm"
          color="danger"
          icon="new_releases"
        >
          กรุณากรอกข้อมูลให้ครบถ้วน
        </vs-alert>
      </div>
    </vs-prompt>


    <div v-if="enable">
      <vs-list>
        <vs-list-item class="k-nav-bar" title="ประทับตรา" subtitle="">
        </vs-list-item>
      </vs-list>
      <vs-card style="margin-top: 10px">
        <div>
          <vs-table stripe :data="tranItems">
            <template slot="header"> </template>

            <template slot="thead" style="margin-top: 100px">
              <vs-th> </vs-th>
              <vs-th> เวลาเข้า </vs-th>
              <vs-th> เวลาออก </vs-th>
              <vs-th> ประเภทรถ </vs-th>
              <vs-th> ทะเบียน </vs-th>
              <vs-th> วัตถุประสงค์ </vs-th>
              <vs-th> ตราประทับ </vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td>
                  <vs-button
                    @click="select(data[indextr])"
                    :disabled="data[indextr].date_out != null"
                    color="primary"
                    type="filled"
                    >ประทับตรา</vs-button
                  >
                </vs-td>
                <vs-td :data="data[indextr].date_in">
                  {{ prepareDate(data[indextr].date_in) }}
                </vs-td>
                <vs-td :data="data[indextr].date_out">
                  {{ prepareDate(data[indextr].date_out) }}
                </vs-td>
                <vs-td :data="data[indextr].car_type">
                  {{ data[indextr].car_type }}
                </vs-td>
                <vs-td :data="data[indextr].license_plate_id">
                  {{ data[indextr].license_plate_id }}
                </vs-td>
                <vs-td :data="data[indextr].visit_objective_type">
                  {{ data[indextr].visit_objective_type }}
                </vs-td>
                <vs-td :data="data[indextr].stamp_type">
                  {{ data[indextr].stamp_type }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <div>
            <br />
            <paginate
              v-model="page"
              :page-count="total"
              :page-range="3"
              :margin-pages="2"
              :click-handler="inputData"
              :prev-text="'Prev'"
              :next-text="'Next'"
              :container-class="'pagination'"
              :page-class="'page-item'"
            >
            </paginate>
          </div>
        </div>
      </vs-card>
    </div>
    <div v-else>
      <the-advertising :post-details="listAdvertising" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import VueCryptojs from "vue-cryptojs";
import VueCookies from "vue-cookies";
import { db } from "../store/firebaseDb";
const moment = require("moment");
require("moment/locale/th");
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import HomeHeader from "@/layouts/components/custom/HomeHeader.vue";
import Paginate from "vuejs-paginate";
import TheAdvertising from "@/components/advertising/TheAdvertising.vue";
import ListAdvertising from "@/store/listAdvertising.js";
console.log("lang", lang);

Vue.use(VueCryptojs);
Vue.use(VueCookies);
Vue.component("home-header", HomeHeader);
Vue.component("paginate", Paginate);
Vue.component("the-advertising", TheAdvertising);
moment.locale("th");
Vue.use(require("vue-moment"), {
  moment
});

console.log(Vue.moment().locale());
export default {
  data() {
    return {
      default_electronic_stamp: "",
      stampDefaultPrompt: false,
      license_plate_id: "",
      hNumber: "",
      stamp: "",
      houseInfo: {},
      tranItems: [],
      stampItems: [],
      selectRowItem: {},
      activePrompt2: false,
      // pagination
      total: 0, // จำนวนหน้า
      page: 1,
      totalItems: 0, // จำนวนข้อมูลทั้งหมด
      isLoad: true,
      user_cloud_config: {},
      enable: null,
      listAdvertising: {},
      show_per_page: 10,
      customer: {},
    };
  },
  computed: {
    validForm() {
      console.log(this.stamp);
      return !(this.stamp != null && this.stamp == "");
    }
  },
  watch: {
    //watch for value to change and assign it to our currentValue
    stamp: {
      handler(val) {
        console.log("val=" + val);
      }
    }
  },
  methods: {
    documentPath() {
      const customerName = $cookies.get("customer");
      const _documentPath = "customer/" + customerName + "/transactions";
      // console.log("documentPath=" + _ducumentPath);
      return _documentPath;
    },

    // getDbWithCondition() {
    //   this.hNumber = $cookies.get("home");
    //   return db.collection(this.documentPath())
    //       .where("contact_address_name", "==", this.hNumber + "")
    // },

    select(item) {
      this.selectRowItem = item;

      if (
        this.default_electronic_stamp == "" ||
        this.default_electronic_stamp == null
      ) {
        this.activePrompt2 = true;
      } else {
        this.stampDefaultPrompt = true;
        this.license_plate_id = item.license_plate_id;
      }
    },

    prepareDate(date) {
      if (date == null) return "-";
      return moment(new Date(date.seconds * 1000)).format(
        "dddd, Do MMM YYYY HH:mm"
      );
    },

    getDefaultStamp() {
      const customerName = $cookies.get("customer");
      console.log("customerName=", customerName);

      db.collection("users")
        .where("customer_name", "==", customerName + "")
        .get()
        .then(custInfo => {
          custInfo.forEach(doc => {
            // console.log("custI nfo doc.data()", doc.data());
            this.default_electronic_stamp = doc.data().default_electronic_stamp;
            console.log(
              "default_electronic_stamp=" + this.default_electronic_stamp
            );
            // this.stampItems.push(doc.id);
          });
          // console.log("custInfo=", custInfo);
        })
        .catch(error => {
          console.log(error);
        });
    },

    stamp_activity() {

      console.log(
        "[[stamp_activity]] this.default_electronic_stamp=" +
          this.default_electronic_stamp+",this.selectRowItem.key="+this.selectRowItem.key
      );

      const customerName = $cookies.get("customer");
      this.hNumber = $cookies.get("home");

      this.stamp = this.default_electronic_stamp;

      this.updateTrans();

      // const updateData = { stamp_type: this.default_electronic_stamp };
      // event.preventDefault();

      // db.collection(this.documentPath())
      //   .doc(this.selectRowItem.key)
      //   .update(updateData)
      //   .then(() => {

      //     this.selectRowItem.stamp_type = this.default_electronic_stamp;

      //     this.selectRowItem = {};
      //     this.stamp = "";

      //     this.$vs.notify({
      //       color: "danger",
      //       title: "",
      //       text: "บันทึกข้อมูลสำเร็จแล้ว"
      //     });

      //   })
      //   .catch(error => {
      //     console.log(error);
      //   });
    },

    updateTrans() {
      console.log("[this.selectRowItem]=",this.selectRowItem);

      const customerName = $cookies.get("customer");
      this.hNumber = $cookies.get("home");

      const updateData = { stamp_type: this.stamp };
      event.preventDefault();

      const ticket_no = this.selectRowItem.ticket_no;

      const refThis = this;

      db.collection(this.documentPath()).where("ticket_no","==",ticket_no).get().then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
            console.log(">>doc=",doc.data());
            doc.ref.update({
                stamp_type: refThis.stamp
            }).then(() => {
              console.log("update success");
            })
            .catch(error => {
              console.log("update error",error);
            });
        });
      });

      setTimeout(function() {
          refThis.selectRowItem = {};
          refThis.stamp = "";
          refThis.$vs.notify({
            color: "danger",
            title: "",
            text: "บันทึกข้อมูลสำเร็จแล้ว"
          });
      },2000);

      // db.collection(this.documentPath())
      //   .doc(this.selectRowItem.key)
      //   .update(updateData)
      //   .then(() => {

      //     this.selectRowItem.stamp_type = this.stamp;

      //     this.selectRowItem = {};
      //     this.stamp = "";

      //     this.$vs.notify({
      //       color: "danger",
      //       title: "",
      //       text: "บันทึกข้อมูลสำเร็จแล้ว"
      //     });
      //   })
      //   .catch(error => {
      //     console.log(error);
      //   });
    },

    close() {
      this.stamp = "";
      this.selectRowItem = {};
    },

    isHavePrefixAddress() {
      return !(
        this.houseInfo.prefix_address == null ||
        this.houseInfo.prefix_address == ""
      );
    },

    async getLastItem() {
      console.log("[[getLastItem]]");

      var _limit = (this.page - 1) * this.show_per_page;
      console.log("this.page=" + this.page);
      console.log("this.show_per_page=" + this.show_per_page);
      console.log("_limit=" + _limit);

      if (_limit == 0) return null;

      this.hNumber = $cookies.get("home");
      let reference = null;

      if (this.isHavePrefixAddress())
      {
        reference = db
          .collection(this.documentPath())
          .where("contact_address_name", "==", this.hNumber + "")
          .where("prefix_address", "==", this.houseInfo.prefix_address + "");
      } else {
        reference = db
          .collection(this.documentPath())
          .where("contact_address_name", "==", this.hNumber + "")
          .where("prefix_address", "==", "");
      }

      reference = reference.orderBy("date_in", "desc");
      reference = reference.limit(_limit);

      const snapshot = await reference.get();
      console.log("snapshot.docs.length=" + snapshot.docs.length);

      const last = snapshot.docs[snapshot.docs.length - 1];
      return last;
    },


    async getTotalRow() {
      let totalCustomer = await this.getDbWithCondition(true, null)
        .get()
        .then((document) => document.docs.length);

      console.log("totalCustomer=" + totalCustomer);

      this.total = Math.ceil(totalCustomer / this.show_per_page);
      this.totalItems = totalCustomer;
    },

    getDbWithCondition(isForCountAll = false, lastItem) {
      console.log("[[getDbWithCondition]] this.hNumber ="+this.hNumber );

      this.hNumber = $cookies.get("home");
      var reference = null;

      if (this.isHavePrefixAddress()) {
        reference = db
          .collection(this.documentPath())
          .where("contact_address_name", "==", this.hNumber + "")
          .where("prefix_address", "==", this.houseInfo.prefix_address + "");
      } else {
        reference = db
          .collection(this.documentPath())
          .where("contact_address_name", "==", this.hNumber + "")
          .where("prefix_address", "==", "");

      }

      if (isForCountAll == true) {
        return reference;
      }

      reference = reference.orderBy("date_in", "desc");

      console.log("this.show_per_page=" + this.show_per_page);

      if (lastItem != null) {
        reference = reference.startAfter(lastItem).limit(this.show_per_page);
      } else {
        reference = reference.limit(this.show_per_page);
      }

      return reference;
    },

    async listTrans({ page = 1 }) {
      console.log("page=" + page);

      this.isLoad = true;
      let list = [];
      this.tranItems = [];
      this.getTotalRow();

      await this.getLastItem().then(async (lastItem) => {
        console.log("lastItem", lastItem);

        let ref = this.getDbWithCondition(false, lastItem);

        let snapshot = await ref.get();
        snapshot.forEach((doc) => {
            console.log("!!doc.data()", doc.data());
            var obj = doc.data();
              obj.key = doc.id;
              list.push(obj);
        });
      });
      this.tranItems = list;    
      console.log(">>>>this.tranItems", this.tranItems);
    },
    async getCustomer() {
      const customerName = $cookies.get("customer");
      const customerRef = db.collection("customer").doc(customerName);

      let thisObj = this;

      await customerRef.get().then((cusInfo) => {
        thisObj.customer = cusInfo.data();
        thisObj.listStampFromAdditionField();
        if( thisObj.stampItems.length == 0 ){
          thisObj.listStamp();
        }
      });
    },

    listStamp() {
      const customerName = $cookies.get("customer");
      this.hNumber = $cookies.get("home");
      this.stampItems = [];
      db.collection("customer/" + customerName + "/stamp").onSnapshot(
        snapshotChange => {
          this.tranItems = [];
          snapshotChange.forEach(doc => {
            console.log("stamp doc.data()", doc.data());
            this.stampItems.push(doc.id);
          });
        }
      );
    },

    isEmpty(checkValue) {
      if (checkValue == undefined || checkValue == null || checkValue == "") {
        return true;
      }

      return false;
    },

    listStampFromAdditionField() {
      console.log("[[listStampFromAdditionField]]");
      const addition_fields = this.customer.addition_fields;
      // console.log("addition_fields_report=", addition_fields);

      if (!this.isEmpty(addition_fields)) {
         
        const addition_fields_obj = JSON.parse(addition_fields);
         let fields_out = addition_fields_obj.fields_out;
         console.log("fields_out=", fields_out);
         if( !this.isEmpty(fields_out)) {
          for( let i = 0 ; i < fields_out.length ; i++ ) {
            let item = fields_out[i];
            
            if( item.valueField == "stamp_type"){
              let collection_arr = item.collection_arr;
              if( !this.isEmpty(collection_arr) ){
                let jsonObj = JSON.parse(collection_arr);
                if( !this.isEmpty(jsonObj) && jsonObj.length >= 2 ){
                  this.stampItems = jsonObj;
                }
              }
            }
          }
         }
      }
    },

    previousData: function () {
      this.listTrans({ page: this.page });
    },
    nextData: function () {
      this.listTrans({ page: this.page });
    },
    inputData: function (value) {
      this.page = value;
      this.listTrans({ page: this.page });
    },
  },
  components: {
    Datepicker
  },
  async created() {
    this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", false);
    var parameters = this.$route.query;

    var home = $cookies.get("home");
    console.log("home", home);

    this.houseInfo = $cookies.get("houseinfo");
    console.log("this.houseInfo=", this.houseInfo);
    this.hNumber = $cookies.get("home");

    if (
      typeof this.houseInfo.active !== "undefined" ||
      this.houseInfo.active !== null
    ) {
      console.log("this.houseInfo.active => ", this.houseInfo.active);
      if (this.houseInfo.active === false) {
        this.$vs.notify({
          title: "<center>VCar Park</center>",
          text: "ไม่มี สิทธิ์ใช้งานส่วนเสริม",
          color: "danger",
          position: "top-center"
        });
        setTimeout(() => this.$router.push({ path: "/pages/error-403" }), 2000);
      }
    }
    await this.getCustomer();
    this.getDefaultStamp();
    this.listTrans({page : this.page});
    // this.listStamp();
    this.user_cloud_config = $cookies.get("user_cloud_config");
    this.enable = this.user_cloud_config.enable_electronic_stamp;
    this.listAdvertising = ListAdvertising.page_enable_electronic_stamp;
   
  }
};
</script>

<style>
.con-exemple-prompt {
  padding: 10px;
  padding-bottom: 0px;
}
.vs-input {
  width: 100%;
  margin-top: 10px;
}
</style>
